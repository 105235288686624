import React, { FC, useState, useCallback, useMemo } from "react"
import styled from "styled-components"
import { useQuery, useMutation } from "@apollo/client"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import IconButton from "@material-ui/core/IconButton"
import Collapse from "@material-ui/core/Collapse"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import DeleteIcon from "@material-ui/icons/Delete"

import Layout from "../components/Layout"
import { ADMIN_GAME_INSTANCES_QUERY, ADMIN_UPDATE_WHEN_TO_WATCH } from "../queries"
import LoadingView from "../components/LoadingView"
import { edgeToNode } from "../../utils/misc-utils"
import { AdminGameInstances } from "../../../__generated__/AdminGameInstances"
import { AdminUpdateWhenToWatch, AdminUpdateWhenToWatchVariables } from "../../../__generated__/AdminUpdateWhenToWatch"

const NO_DATA_MESSAGE = "No watch dates recorded for this season"

interface IPeriodData {
  periodName: string
  dates: Array<string>
}

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      "& > *": {
        borderBottom: "unset",
      },
    },
    expandColumn: {
      width: "4rem",
    },
    noPaddingCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    formControl: {
      minWidth: 240,
    },
    toolbarButton: {
      marginRight: theme.spacing(2),
    },
  }),
)

type StylesType = ReturnType<typeof useRowStyles>

const GridDisplay = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
`

const toInputValue = (date) => new Date(date).toISOString().split("T", 1)[0]

interface IWhenToWatchEditorProps {
  data: any
  classes: StylesType
  onSave: (a: Array<IPeriodData>) => void
}

const WhenToWatchEditor: FC<IWhenToWatchEditorProps> = ({ data, classes, onSave }) => {
  const [periods, setPeriods] = useState<Array<IPeriodData>>(() => (data ?? []) as Array<IPeriodData>)
  const emptyHandler = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
  }, [])

  const addPeriod = useCallback(() => {
    const newPeriod: IPeriodData = { periodName: "", dates: [] }
    setPeriods((periods) => [...periods, newPeriod])
  }, [])

  const deletePeriod = useCallback(
    (index) => {
      const newPeriods = periods.slice()
      newPeriods.splice(index, 1)
      setPeriods(newPeriods)
    },
    [periods],
  )

  const handlePeriodNameChange = useCallback(
    (index, value) => {
      const newPeriod: IPeriodData = { ...periods[index], periodName: value }
      const newPeriods = periods.slice()
      newPeriods.splice(index, 1, newPeriod)
      setPeriods(newPeriods)
    },
    [periods],
  )

  const handleAddPeriodDate = useCallback(
    (index) => {
      const newPeriod: IPeriodData = { ...periods[index], dates: [...periods[index].dates, new Date().toISOString()] }
      const newPeriods = periods.slice()
      newPeriods.splice(index, 1, newPeriod)
      setPeriods(newPeriods)
    },
    [periods],
  )

  const handlePeriodDateChange = useCallback(
    (index, dateIndex, value) => {
      const newDate = new Date(value).toISOString()
      const newDates = periods[index].dates.slice()
      newDates.splice(dateIndex, 1, newDate)
      const newPeriod: IPeriodData = { ...periods[index], dates: newDates }
      const newPeriods = periods.slice()
      newPeriods.splice(index, 1, newPeriod)
      setPeriods(newPeriods)
    },
    [periods],
  )

  const handlePeriodDateDelete = useCallback(
    (index, dateIndex) => {
      const newDates = periods[index].dates.slice()
      newDates.splice(dateIndex, 1)
      const newPeriod: IPeriodData = { ...periods[index], dates: newDates }
      const newPeriods = periods.slice()
      newPeriods.splice(index, 1, newPeriod)
      setPeriods(newPeriods)
    },
    [periods],
  )

  const periodInputs = periods.map((period, index) => {
    return (
      <Grid container spacing={4} key={index}>
        <Grid item sm={4} container spacing={1}>
          <Grid item>
            <IconButton size="medium" onClick={() => deletePeriod(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              key={index}
              placeholder="Period Name"
              value={period.periodName}
              onChange={(e) => handlePeriodNameChange(index, e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item sm={8}>
          <GridDisplay>
            {period.dates.map((date, dateIndex) => (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton size="small" onClick={() => handlePeriodDateDelete(index, dateIndex)}>
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type="date"
                placeholder="Watch date"
                value={toInputValue(date)}
                key={dateIndex}
                onChange={(e) => handlePeriodDateChange(index, dateIndex, e.target.value)}
                onKeyDown={emptyHandler}
              />
            ))}
            <Button size="small" variant="outlined" onClick={() => handleAddPeriodDate(index)}>
              Add Date
            </Button>
          </GridDisplay>
        </Grid>
      </Grid>
    )
  })

  return (
    <>
      {!periods.length && (
        <Typography variant="body2" color="error">
          {NO_DATA_MESSAGE}
        </Typography>
      )}
      <Box mt={2}>
        <Toolbar>
          <Button onClick={addPeriod} size="medium" className={classes.toolbarButton}>
            Add Period
          </Button>
          <Button onClick={() => onSave?.(periods)} size="medium" variant="contained" color="primary" className={classes.toolbarButton}>
            Save
          </Button>
        </Toolbar>
        <Box mt={2}>{periodInputs}</Box>
      </Box>
    </>
  )
}

const GameInstance: FC<{ data: any }> = ({ data }) => {
  const classes = useRowStyles()

  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  const [seasonId, setSeasonId] = useState<string>("")
  const handleSeasonChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSeasonId(String(e.target.value))
  }, [])

  const [updateWhenToWatch, { loading }] = useMutation<AdminUpdateWhenToWatch, AdminUpdateWhenToWatchVariables>(ADMIN_UPDATE_WHEN_TO_WATCH, {
    refetchQueries: [
      {
        query: ADMIN_GAME_INSTANCES_QUERY,
      },
    ],
    awaitRefetchQueries: true,
  })

  const handleSave = useCallback(
    (seasonId: string, data: Array<IPeriodData>) => {
      updateWhenToWatch({
        variables: {
          seasonId: seasonId,
          data: data.map((d) => ({ periodName: d.periodName, dates: d.dates })),
        },
      })
    },
    [updateWhenToWatch],
  )

  const gameInstance = data?.node
  const gameInstanceUid = gameInstance?.uid ?? ""
  const currentPeriod = gameInstance?.currentPeriod
  const currentSegment = currentPeriod?.segment
  const gameType = currentSegment?.gameType ?? "(none)"
  const seasons = useMemo(() => gameInstance?.seasons?.edges?.map(edgeToNode) ?? [], [gameInstance?.seasons])
  const seasonMap = useMemo(() => {
    const map = new Map<string, any>()
    for (const season of seasons) {
      map.set(season.id, season)
    }
    return map
  }, [seasons])
  const seasonOptions = seasons.map((season) => (
    <FormControlLabel key={season.id} value={season.id} label={`${season.year}  ${season.season}`} control={<Radio />} />
  ))

  return (
    <React.Fragment>
      <TableRow className={classes.root} onClick={toggle}>
        <TableCell className={classes.expandColumn}>
          <IconButton size="small" onClick={toggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{gameInstanceUid}</TableCell>
        <TableCell>{gameType}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPaddingCell} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Container>
                <Grid container spacing={8}>
                  <Grid item sm={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Season</FormLabel>
                      <RadioGroup aria-label="season" name="season" value={seasonId} onChange={handleSeasonChange}>
                        {seasonOptions}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item sm={10}>
                    {loading ? (
                      <LoadingView />
                    ) : seasonId ? (
                      <WhenToWatchEditor
                        classes={classes}
                        data={seasonMap.get(seasonId)?.whenToWatch}
                        onSave={(data) => handleSave(seasonId, data)}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const WhenToWatchManager: FC = () => {
  const { data, loading } = useQuery<AdminGameInstances>(ADMIN_GAME_INSTANCES_QUERY)
  const gameInstances = data?.gameInstances?.edges ?? []

  const gameInstanceRows = gameInstances.map((gi) => <GameInstance key={gi.node.id} data={gi} />)

  return (
    <Layout title="Marketing - When to watch">
      {loading || !gameInstances.length ? (
        <LoadingView />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell component="th" scope="row">
                  Game Instance UID
                </TableCell>
                <TableCell>Game Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{gameInstanceRows}</TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  )
}

export default WhenToWatchManager
